<template id="message">
  <div class="pa-1">
    <div
      class="message text-md-caption"
      v-for="(message, index) in messages"
      v-bind:key="index"
      :class="{ own: message.userid == username }"
    >
      <div
        class="username text-md-subtitle-2"
        v-if="index > 0 && messages[index - 1].userid == username"
      >
        <span class="text-md-caption">{{ message.time | moment }}</span> - {{ message.username }}
      </div>
      <div class="username text-sm-subtitle-2" v-if="index == 0">
        {{ message.username }} - <span class="text-md-caption">{{ message.time | moment }}</span>
      </div>
      <div style="margin-top: 5px"></div>
      <div class="content ma-1">
        <div v-html="message.message"></div>
        <chat-image
          v-if="message.image"
          :imgsrc="message.image"
          @imageLoad="imageLoad"
        ></chat-image>
      </div>
    </div>
  </div>
</template>

<script>
import Image from "./Image.vue";
import moment from "moment";
export default {
  id: '#message',
  data() {
    return {};
  },
  props: ["messages", "username"],
  components: {
    "chat-image": Image,
  },
  computed: {
    // username () {
    //   return this.$store.getters.user.username
    // }
  },
  filters: {
    moment: function (date) {
      return moment(date).format("h:mm:ss a");
    },
  },
  methods: {
    imageLoad() {
      // this.$emit('imageLoad')
    },
  },
};
</script>

<style>
span.emoji {
  font-size: 20px;
  vertical-align: middle;
  line-height: 2;
}
</style>
