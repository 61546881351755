<template id="#chatbox">
  <v-container fluid>
    <v-row>
      <v-col>
        <h3>{{ title }}</h3>
      </v-col>
      <v-icon
        :color="'red'"
        medium
        class="float-sm-right pr-1"
        v-on:click="clearChat()"
        @click="$emit('close-dialog-chat')"
        v-text="'mdi-close-circle-outline'"
      ></v-icon>
    </v-row>
    <v-row class="d-flex justify-center pa-1">
      <v-col>
        <div class="chat-container" v-on:scroll="onScroll" ref="chatContainer">
          <message
            :messages="chatMessages"
            @imageLoad="scrollToEnd"
            :username="userid"
          ></message>
        </div>
        <emoji-picker
          :show="emojiPanel"
          @close="toggleEmojiPanel"
          @click="addEmojiToMessage"
        ></emoji-picker>
        <div class="typer">
          <input
            type="text"
            placeholder="ketikan pesan disini"
            v-on:keyup.enter="sendMessage"
            v-model="content"
          />
          <v-btn icon class="blue--text emoji-panel" @click="toggleEmojiPanel">
            <v-icon>mdi-emoticon-outline</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Message from "./parts/Message.vue";
import EmojiPicker from "./parts/EmojiPicker.vue";
import ApiSservice from "../../services/api.js";
import axios from "axios";
import { createEvent } from "../../services/index";
const service = new ApiSservice();
export default {
  id: "#chatbox",
  data() {
    return {
      content: "",
      chatMessages: [],
      emojiPanel: false,
      currentRef: {},
      loading: false,
      totalChatHeight: 0,
      username: "",
      userid: "",
      intervalid: "",
      newMessages: false,
      auth: {},
    };
  },

  props: ["id", "chats", "title"],
  beforeMount() {},
  created() {
    this.chatMessages = this.chats;
  },
  mounted() {
    this.chatMessages = this.chats;
    this.auth = JSON.parse(localStorage.getItem("AUTH"));
    if (this.auth && this.auth != null) {
      this.username = this.auth.Nama;
      this.userid = this.auth.userid;
    }

    this.loadChat();
    this.loadIntervalChat();
  },
  components: {
    message: Message,
    "emoji-picker": EmojiPicker,
    //'chats': Chats
  },
  beforeDestroy() {
    clearInterval(this.intervalid);
  },
  computed: {
    messages() {
      return this.chatMessages;
    },
    // username() {
    //   this.auth = JSON.parse(localStorage.getItem("AUTH"));
    //   return this.auth.userid;
    // },
    // onNewMessageAdded () {
    //   // const that = this
    //   // let onNewMessageAdded = function (snapshot, newMessage = true) {
    //   //   let message = snapshot.val()
    //   //   message.key = snapshot.key
    //   //   /*eslint-disable */
    //   //   var urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig
    //   //   /*eslint-enable */
    //   //   message.content = message.content
    //   //     .replace(/&/g, '&amp;')
    //   //     .replace(/</g, '&lt;')
    //   //     .replace(/>/g, '&gt;')
    //   //     .replace(/"/g, '&quot;')
    //   //     .replace(/'/g, '&#039;')
    //   //   message.content = message.content.replace(urlPattern, "<a href='$1'>$1</a>")
    //   //   if (!newMessage) {
    //   //     that.chatMessages.unshift(that.processMessage(message))
    //   //     that.scrollTo()
    //   //   } else {
    //   //     that.chatMessages.push(that.processMessage(message))
    //   //     that.scrollToEnd()
    //   //   }
    //   // }
    //   // return onNewMessageAdded
    // }
  },
  watch: {
    // "$route.params.id"(newId, oldId) {
    //   console.log(newId);
    //   console.log(oldId);
    //   //this.currentRef.off('child_added', this.onNewMessageAdded)
    //   this.loadChat();
    // },
    chats: function () {
      this.loadChat();
    },
  },
  methods: {
    clearChat() {
      this.chatMessages = [];
      console.log(this.newMessages);
      if (this.newMessages) {
        this.approve("Progress");
      }
      clearInterval(this.intervalid);
    },
    approve() {
      const status =
        this.auth.level == "admin" ? "Progress" : "Draft";
      axios
        .put(createEvent + "/" + this.id, { status: status })
        .then((result) => {
          if (result.status == 200) this.newMessages = false;
        });
    },
    loadIntervalChat() {
      this.intervalid = setInterval(() => {
        service.serviceGet(this.id).then((res) => {
          if (res.status == 200)
            this.chatMessages =
              res.results.content.length > 0
                ? JSON.parse(res.results.content)
                : [];
        });
      }, 2500);
    },
    loadChat() {
      this.totalChatHeight = this.$refs.chatContainer.scrollHeight;
      this.loading = true;
      this.chatMessages = [];
      if (this.id !== undefined) {
        let chatID = this.id;
        service.serviceGet(chatID).then((res) => {
          switch (res.status) {
            case 404:
              this.snackbarText = res.message;
              this.loading = false;
              break;
            default:
              this.chatMessages =
                res.results.content.length > 0
                  ? JSON.parse(res.results.content)
                  : [];
              this.loading = false;
          }
        });
      }
    },
    onScroll() {
      let scrollValue = this.$refs.chatContainer.scrollTop;
      // const that = this
      if (scrollValue < 100 && !this.loading) {
        this.totalChatHeight = this.$refs.chatContainer.scrollHeight;
        this.loading = true;
        //let chatID = this.id
        let currentTopMessage = this.chatMessages[0];
        if (currentTopMessage === undefined) {
          this.loading = false;
          return;
        }
        // firebase.database().ref('messages').child(chatID).child('messages').orderByKey().endAt(currentTopMessage.key).limitToLast(20).once('value').then(
        //   function (snapshot) {
        //     let tempArray = []
        //     snapshot.forEach(function (item) {
        //       tempArray.push(item)
        //     })
        //     if (tempArray[0].key === tempArray[1].key) return
        //     tempArray.reverse()
        //     tempArray.forEach(function (child) { that.onNewMessageAdded(child, false) })
        //     that.loading = false
        //  }
        // )
      }
    },
    processMessage(message) {
      /*eslint-disable */
      var imageRegex = /([^\s\']+).(?:jpg|jpeg|gif|png)/i;
      /*eslint-enable */
      if (imageRegex.test(message.content)) {
        message.image = imageRegex.exec(message.content)[0];
      }
      var emojiRegex =
        /([\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{2934}-\u{1f18e}])/gu;
      if (emojiRegex.test(message.content)) {
        message.content = message.content.replace(
          emojiRegex,
          '<span class="emoji">$1</span>'
        );
      }
      return message;
    },
    sendMessage() {
      if (this.content !== "") {
        var msg = {
          userid: this.userid,
          username: this.username,
          message: this.content,
          time: Date.now(),
        };
        this.chatMessages.push(msg);
        this.content = "";
        service
          .saveMessage({ message: this.chatMessages }, this.id)
          .then((res) => {
            switch (res.status) {
              case 404:
                this.snackbarText = res.message;
                this.loading = false;
                break;
              default:
                this.loadChat();
                this.loading = false;
                this.newMessages = true;
            }
          });
      }
    },
    scrollToEnd() {
      this.$nextTick(() => {
        var container = this.$el.querySelector(".chat-container");
        container.scrollTop = container.scrollHeight;
      });
    },
    scrollTo() {
      this.$nextTick(() => {
        let currentHeight = this.$refs.chatContainer.scrollHeight;
        let difference = currentHeight - this.totalChatHeight;
        var container = this.$el.querySelector(".chat-container");
        container.scrollTop = difference;
      });
    },
    addEmojiToMessage(emoji) {
      this.content += emoji.value;
    },
    toggleEmojiPanel() {
      this.emojiPanel = !this.emojiPanel;
    },
  },
};
</script>

<style>
.scrollable {
  overflow-y: auto;
  height: 90vh;
}
.typer {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  bottom: 0;
  height: 4.9rem;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);
}
.typer input[type="text"] {
  position: absolute;
  left: 2.5rem;
  padding: 1rem;
  width: 80%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.25rem;
}
.chat-container {
  box-sizing: border-box;
  height: calc(100vh - 15rem);
  overflow-y: auto;
  background-color: #f2f2f2;
}
.message {
  margin-bottom: 3px;
}
.message.own {
  text-align: right;
}
.message.own .content {
  background-color: lightskyblue;
}
.chat-container .username {
  font-size: 18px;
  font-weight: bold;
}
.chat-container .content {
  padding: 8px;
  background-color: lightgreen;
  border-radius: 10px;
  display: inline-block;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  max-width: 50%;
  word-wrap: break-word;
}
@media (max-width: 480px) {
  .chat-container .content {
    max-width: 60%;
  }
}
</style>
