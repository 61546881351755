import Vue from "vue";
import VueRouter from "vue-router";
import {
  About,
  Home,
  Kegiatan,
  Login,
  Settings,
  User,
  Team,
  Category,
  Web,
} from "../router/micro";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { title: "Home - Dashboard" }, //
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: { title: "Tentang - Dashboard" }, //
  },
  {
    path: "/kegiatan",
    name: "Kegiatan",
    component: Kegiatan,
    meta: { title: "Kegiatan - Dashboard" }, //
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { title: "Login - Dashboard" }, //
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: { title: "Settings - Dashboard" }, //
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
    meta: { title: "Team - Dashboard" }, //
  },
  {
    path: "/user",
    name: "User",
    component: User,
    meta: { title: "User Management - Dashboard" }, //
  },
  {
    path: "/category",
    name: "Category",
    component: Category,
    meta: { title: "Category - Dashboard" }, //
  },
  {
    path: "/web",
    name: "Web",
    component: Web,
    meta: { title: "Web - Dashboard" }, //
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
