import Vue from "vue";
import App from "./App.vue";
// import PortalVue from "portal-vue";
import router from "./router";
import store from "./store";
import Vuetify, {
  // Components used in demo
  VApp, VContent, VContainer,
  // Components used in this package
  VDialog, VCard, VCardTitle, VCardText, VCardActions, VBtn, VSpacer, VIcon, VTextField, VTooltip, VToolbar
} from 'vuetify/lib'
import { ValidationObserver, ValidationProvider } from "vee-validate";
import VueMeta from 'vue-meta'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'

window.$ = window.jQuery = require("jquery");
// Vue.use(PortalVue);
Vue.config.productionTip = false;
const vuetify = new Vuetify({
  lang: {
    current: 'en' // en | es | fr | pl | ru | uk | ptbr | tr | he | nl | ja | de | ko | zhHans | fa | sv | cs | it | el
  }
})
//axios.defaults.baseURL = "http://localhost:8080/";
Vue.use(VueMeta)
Vue.use(Vuetify, {
  components: {
    // Components used in demo
    VApp,
    VContent,
    VContainer,
    // Components used in this package
    VDialog,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VBtn,
    VSpacer,
    VIcon,
    VTextField,
    VTooltip,
    VToolbar
  },
  directives: {}
})
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
